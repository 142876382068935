import { styled } from "styled-components";
import BackgroundImage from "../assets/background_img.webp";

export const LandingWrapper = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 70rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoWrapper = styled.div``;

export const Logo = styled.img`
  height: 300px;
  width: 300px;
`;

export const HeadingH1 = styled.h1`
  color: #ffffff;
  font-size: 140px;
  font-weight: 700;
  font-family: "Outfit", serif;
  font-style: normal;
  line-height: 1.2em;
  letter-spacing: 0px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const ContentH2 = styled.h2`
  font-size: 32px;
  color: #ffffff;
  font-weight: 600;
  font-family: "Outfit", serif;
  font-style: normal;
  line-height: 1.4em;
  letter-spacing: 0px;
`;
