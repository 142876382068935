import React from "react";
import {
  LandingWrapper,
  LogoWrapper,
  Logo,
  HeadingH1,
  ContentH2,
} from "../styles/LandingStyle";
import LogoImg from "../assets/logo.png";
import CTA from "./CTA";

const Landing = () => {
  return (
    <LandingWrapper>
      <LogoWrapper>
        <Logo src={LogoImg} />
      </LogoWrapper>
      <HeadingH1>Coming Soon</HeadingH1>
      <ContentH2>In mean time, join our waitlist.</ContentH2>
      <CTA />
    </LandingWrapper>
  );
};

export default Landing;
