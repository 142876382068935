import React from "react";
import { CTAWrapper, CTAInput, CTAButton } from "../styles/CTAStyle";

const CTA = () => {
  return (
    <CTAWrapper onSubmit={(e) => e.preventDefault()}>
      <CTAInput placeholder="Email Address" type="email" />
      <CTAButton type="submit">Sign Up</CTAButton>
    </CTAWrapper>
  );
};

export default CTA;
