import { styled } from "styled-components";

export const CTAWrapper = styled.form`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

export const CTAInput = styled.input`
  padding: 30px;
  border-radius: 50px;
  background: rgb(255, 255, 255);
  color: rgb(34, 34, 34);
  box-shadow: inset 0 0 0 1px transparent;
  border: none;
  width: 20rem;
  margin-right: 2rem;
  outline: none;
`;

export const CTAButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 20px;
  border-radius: 40px;
  font-weight: 600;
  font-size: 16px;
  background: rgb(34, 34, 34);
  color: rgb(255, 255, 255);
`;
